import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonProps } from './Button.interface';

import styles from './Button.module.scss';
import { Button, Link } from 'react-aria-components';


const MyButton = ({
  type = 'button',
  variation = 'primary',
  size = 'regular',
  disabled = false,
  url = '',
  onClick = () => null,
  isInternal = true,
  children,
  ...props
}: ButtonProps) => {

  const navigate = useNavigate();

  /**
   * Check if the (optional) URL is internal.
   */
  // const isInternal = useMemo(
  //   () => !url
  //     || url.startsWith('/')
  //     || (new URL(url).origin === window.location.origin && !url.includes('download')),
  //   [url]
  // );

  /**
   * Handle clicks.
   */
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick(e);

    // Ignore meta key clicks on anchors (eg. manual new tab).
    if (url && e.metaKey) {
      return;
    }

    // Route internal URLs to our router.
    if (url && isInternal) {
      navigate(url);
    }
  };

  if (url) {
    return (
      <Link
        {...props}
        className={styles.root}
        data-size={size}
        data-variation={variation}
        href={url}
        target={isInternal ? '' : '_blank'}
        rel={isInternal ? '' : 'external noopener noreferrer'}
        onPress={clickHandler as any}
        isDisabled={disabled}
      >
        {children}
      </Link>
    );
  }

  return (
    <Button
      {...props}
      className={styles.root}
      type={type}
      data-size={size}
      data-variation={variation}
      onPress={clickHandler as any}
      isDisabled={disabled}
    >
      {children}
    </Button>
  );

};

export default MyButton;
