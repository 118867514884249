export const USER_DEFAULTS = {
  id: '',
  authenticated: false,
  is_admin: false,
  can_approve: false,
  is_approver: false,
  is_preparer: false,
  is_superuser: false,
  is_file_uploader: false,
  show_landing_tour: false,
  show_guided_tour: false,
  show_aside_tour: false,
  show_roles_tour: false,
  show_filters_tour: false,
  show_content_tour: false,
  groups: [],
  profile: {
    id: '',
    email: '',
    identifier: '',
    client: {
      identifier: '',
      name: '',
    },
    groups: ['', ''],
    first_name: '',
    initials: '',
    is_active: false,
    is_onboarded: false,
    is_staff: false,
    is_superuser: false,
    is_tester: false,
    has_access: false,
    job_role_list: [],
    last_name: '',
    marked_for_deletion: false,
    marked_for_deletion_count: 0,
    user_permissions: [''],
    username: '',
    name: ''
  },
};
