
export const ROLE_LIST = [
  { label: "Legal", checked: false },
  { label: "Compliance", checked: false },
  { label: "Sustainability", checked: false },
  { label: "Marketing & Communications", checked: false },
  { label: "Human Resources", checked: false },
  { label: "Product Design & Development", checked: false },
  { label: "Sales", checked: false },
  { label: "Supply Chain", checked: false },
  { label: "Manufacturing / Operations", checked: false },
  { label: "Retail", checked: false },
  { label: "IT & Data", checked: false },
  { label: "Quality Assurance", checked: false },
];

export const SUSTAINABILITY_LIST = [
  { label: "Plastic Packaging & Packaging EPR", checked: false },
  { label: "Workers in the value chain", checked: false },
  { label: "Labelling", checked: false },
  { label: "Greenwashing", checked: false },
  { label: "Eco-design & Digital Product Passport", checked: false },
  { label: "Sustainability Reporting", checked: false },
];

export const ONBOARDING_DIALOG_ID = 'sln-onboarding-modal-1';
export const STORAGE_KEY_ONBOARDED = 'sln-onboarded';
