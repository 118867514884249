import { useEffect, useMemo, useState } from 'react';
import { Key, ListBoxItem } from 'react-aria-components';
import { Select } from 'components';
import { TeamMemberProps } from './TeamMember.interface';

import styles from './TeamMember.module.scss';
import { Client } from 'hooks/interfaces/project.interface';

const formatRole = (data: Client) => data.role?.charAt(0).toUpperCase() + data.role?.slice(1);


const TeamMember = ({
  data,
  query = '',
  disabled,
  onRemove,
  onSelect,
  options = [],
  type = 'create',
  onSelectionChange,
  ...props
}: TeamMemberProps & {
  onSelectionChange?: (role: Key, data: any) => void;
}) => {

  const [selected, setSelected] = useState<Key | null>(formatRole(data) || data.groups[0] || 'Preparer');

  /**
   * Handles setting the selected role.
   */
  useEffect(() => {
    setSelected(formatRole(data) || 'Preparer');
  }, [data]);

  return (
    <article className={styles.root} data-type={type} {...props}>
      <div className={styles.root__info}>
        <p>{data.email}</p>
      </div>
      <div className={styles.root__dropdown} data-type={type}>
        <Select
          title={`Change user role for ${data.email}`}
          items={[ ...new Set([ ...options, formatRole(data)])].map((o, index) => ({ name: o, id: index }))}
          selectedKey={selected}
          onSelectionChange={_selected => {
            setSelected(_selected);
            onSelectionChange?.(_selected, data);
            onSelect(_selected, data);
          }}
        >
          {(item) => <ListBoxItem id={item.name} key={`${item.name}-${item.id}`} data-select-item>{item.name}</ListBoxItem>}
        </Select>
      </div>
    </article>
  );

};

export default TeamMember;
