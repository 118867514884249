export default {
  type: 'error',
  title: '',
  message: '',
  active: false,
  persistent: true,
  reload: false,
  button: {
    label: '',
    url: '',
  },
};
