import { useRef, useState, useCallback } from 'react';

type InferDefined<T> = T extends infer U | undefined ? U : never;

/**
 * A hook for controlled value management.
 * In the case of passing the controlled value, the controlled value is returned, otherwise the value in state is returned.
 * Generally used for a component including controlled and uncontrolled modes.
 * @param controlledValue
 * @param defaultValue
 * @param formatValue
 */
function useControlled<V = any, D = V>(
  controlledValue: V,
  defaultValue: D
): [
    V extends undefined ? D : InferDefined<V>,
    (value: React.SetStateAction<V | null>) => void,
    boolean
  ];
function useControlled(controlledValue: any, defaultValue: any) {
  const controlledRef = useRef(false);
  controlledRef.current = controlledValue !== undefined;

  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

  // If it is controlled, this directly returns the attribute value.
  const value = controlledRef.current ? controlledValue! : uncontrolledValue;

  const setValue = useCallback(
    (nextValue: any) => {
      // Only update the value in state when it is not under control.
      if (!controlledRef.current) {
        setUncontrolledValue(nextValue);
      }
    },
    [controlledRef]
  );

  return [value, setValue, controlledRef.current];
}

const KEY_VALUES = {
  // Navigation Keys
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  END: 'End',
  HOME: 'Home',
  PAGE_DOWN: 'PageDown',
  PAGE_UP: 'PageUp',

  // Whitespace Keys
  ENTER: 'Enter',
  TAB: 'Tab',
  SPACE: ' ',

  // Editing Keys
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  COMMA: ',',

  // UI Keys
  ESC: 'Escape',
  SHIFT: 'Shift'
};

function getText(rootChild: any) {
  let res = ''
  const rr = (child: any) => {
    if (typeof child === 'string' || typeof child === 'number') {
      res += child
    } else if (Array.isArray(child)) {
      child.forEach(c => rr(c))
    } else if(child && child.props) {
      const {children} = child.props

      if (Array.isArray(children)) {
        children.forEach(c => rr(c) )
      } else {
        rr(children)
      }
    }
  };

  rr(rootChild);

  return res;
}

export {
  KEY_VALUES,
  getText,
  useControlled,
};


